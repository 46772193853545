import React from "react"
import { Layout, FlexCol, Parallax } from "@components/layout"
import {
  Breadcrumbs,
  ResizedImage as Image,
  AnimatedButton,
} from "@components/shared"

import {
  page__wrapper,
  page__header,
  page__content,
  page__content__btn,
} from "./styles/thanks.module.scss"

const Thanks = ({ pageContext }) => {
  const { page } = pageContext
  return (
    <Layout
      animation={false}
      seo={pageContext.page.seo}
      siteMetaData={pageContext.page.siteMetaData}
    >
      <Parallax height={300}>
        <h4>Dziękujemy</h4>
      </Parallax>
      <div className={page__wrapper}>
        <div className={page__content}>
          <Breadcrumbs
            elements={[
              {
                label: page.title,
                url: `/${page.slug}/`,
              },
            ]}
          />
          <h4>Dziękujemy za Twoje wskazówki!</h4>
          <h5>
            Dzięki nim, będziemy mogli trafniej dobierać treści i materiały
            które będziesz od nas otrzymywać.
          </h5>
        </div>
      </div>
    </Layout>
  )
}

export default Thanks
